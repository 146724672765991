let STORAGE_KEY = "forked-page";

let positions: { [key: string]: number } = {};

if (typeof document !== "undefined") {
  let sessionPositions = sessionStorage.getItem(STORAGE_KEY);
  if (sessionPositions) {
    positions = JSON.parse(sessionPositions);
  }
}

export function setCooking(recipeData, force = false) {
  if (typeof document !== "undefined") {
    // console.log("setCooking", recipeData);
    if (force) {
      localStorage.setItem("forked-cooking", JSON.stringify(recipeData));
      return;
    }
    const existing = getCooking();
    if (existing) {
      //get the ids of the steps passing over
      const recipeIds = recipeData.map((s) => s.uuid);
      //filter out the steps that are already in the existing array
      const existingFiltered = existing.filter((s) => !recipeIds.includes(s.uuid));

      recipeData = [...recipeData, ...existingFiltered];
    }
    localStorage.setItem("forked-cooking", JSON.stringify(recipeData));
  }
}

export function getCooking() {
  if (typeof document !== "undefined") {
    let cooking = localStorage.getItem("forked-cooking");
    if (cooking) {
      return JSON.parse(cooking);
    }
  }
  return [];
}

export function deleteCooking() {
  if (typeof document !== "undefined") {
    localStorage.removeItem("forked-cooking");
  }
}

export function setCurrentCooking(recipeId) {
  if (typeof document !== "undefined") {
    localStorage.setItem("forked-current-cooking", JSON.stringify(recipeId));
  }
}

export function getCurrentCooking() {
  if (typeof document !== "undefined") {
    let cooking = localStorage.getItem("forked-current-cooking");
    if (cooking) {
      return JSON.parse(cooking);
    }
  }
  return null;
}

export function deleteCurrentCooking() {
  if (typeof document !== "undefined") {
    localStorage.removeItem("forked-current-cooking");
  }
}

export function setRecipePlan(planData) {
  if (typeof document !== "undefined") {
    localStorage.setItem("forked-plan", JSON.stringify(planData));
  }
}

export function getRecipePlan() {
  if (typeof document !== "undefined") {
    let plan = localStorage.getItem("forked-plan");
    if (plan) {
      return JSON.parse(plan);
    }
  }
  return null;
}

export function deleteRecipePlan() {
  if (typeof document !== "undefined") {
    localStorage.removeItem("forked-plan");
  }
}

export function setLoggingIn(status) {
  if (typeof document !== "undefined") {
    sessionStorage.setItem("forked-logging-in", status.toString());
  }
}

export function getLoggingIn() {
  if (typeof document !== "undefined") {
    let loggingIn = sessionStorage.getItem("forked-logging-in");
    if (loggingIn) {
      return loggingIn === "true";
    }
  }
  return false;
}

export function deleteLoggingIn() {
  if (typeof document !== "undefined") {
    sessionStorage.removeItem("forked-logging-in");
  }
}
